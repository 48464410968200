<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Usuario.." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Asignar Permisos.."  class="p-button-success p-mr-2" @click="PermisosN" :disabled="!selectedUsuarios || !selectedUsuarios.length" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="usuarios" v-model:selection="selectedUsuarios" :key="estadoKey" dataKey="id_usuario" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} usuarios">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Usuarios</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<!-- <Column field="id_usuario" header="Id" :sortable="true"></Column> -->
					<Column field="clave" header="Clave" :sortable="true"></Column>
                    <Column field="rol" header="Rol" :sortable="true"></Column>
                    <Column field="nombre" header="Nombre" :sortable="true"></Column>
                    <Column field="apellido_paterno" header="Paterno" :sortable="true"></Column>
                    <Column field="apellido_materno" header="Materno" :sortable="true"></Column>
                    <Column field="fecha_alta" header="Alta" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormat(slotProps.data.fecha_alta)}}
                        </template>
					</Column>
                    <Column field="fecha_baja" header="Baja" :sortable="true"></Column>
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editUsuario(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteUsuario(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="usuarioDialog" :style="{width: '650px'}" header="Usuario" :modal="true" class="p-fluid">
					
					<div class="p-field">
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Clave *</label>
								<InputText id="clave" v-model.trim="usuario.clave" required="true" autofocus :class="{'p-invalid': submitted && !usuario.clave}" />
								<small class="p-invalid" v-if="submitted && !usuario.clave">La clave del usuario es requerido</small>
							</div>
							<div class="p-field p-col">
								<label for="contrasena">Contraseña *</label>
								<Password id="contrasena" v-model.trim="usuario.contrasena" required="true" feedback="true" autofocus :class="{'p-invalid': submitted && !usuario.contrasena}" />
								<small class="p-invalid" v-if="submitted && !usuario.contrasena">La contraseña es requerida</small>
							</div>
						</div>
					</div>

					<div class="p-field">
						<label for="nombre">Nombre *</label>
						<InputText id="nombre" v-model.trim="usuario.nombre" required="true" autofocus :class="{'p-invalid': submitted && !usuario.nombre}" />
						<small class="p-invalid" v-if="submitted && !usuario.nombre">El nombre es requerido</small>
					</div>

					<div class="p-field">
						<label for="nombre">Apellido Paterno *</label>
						<InputText id="nombre" v-model.trim="usuario.apellido_paterno" required="true" autofocus :class="{'p-invalid': submitted && !usuario.apellido_paterno}" />
						<small class="p-invalid" v-if="submitted && !usuario.apellido_paterno">El apellido paterno es requerido</small>
					</div>

					<div class="p-field">
						<label for="nombre">Apellido Materno *</label>
						<InputText id="nombre" v-model.trim="usuario.apellido_materno" required="true" autofocus :class="{'p-invalid':submitted && !usuario.apellido_materno}" />
						<small class="p-invalid" v-if="submitted && !usuario.apellido_materno">El apellido materno es requerido</small>
					</div>

					<div class="p-field">
						<label class="p-mb-3">Rol *</label>
						<div class="p-formgrid p-grid">
							<div class="p-field-radiobutton p-col">
								<RadioButton id="rol1" name="rol" value="Admin" v-model="usuario.rol" />
								<label for="rol1">Admin</label>
							</div>
							<div class="p-field-radiobutton p-col">
								<RadioButton id="rol2" name="rol" value="Operación" v-model="usuario.rol" />
								<label for="rol2">Operación</label>
							</div>
						</div>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="celular">Celular</label>
							<InputText id="celular" v-model="usuario.celular" />
						</div>
						<div class="p-field p-col">
							<label for="email">Correo Electrónico</label>
							<InputText id="email" v-model.trim="usuario.email" />
						</div>
					</div>

                    <div class="p-field-checkbox">
                        <Checkbox id="activo" name="activo" :binary="true" v-model="usuario.activo" />
                        <label for="activo">Activo</label>
                    </div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveUsuario" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteUsuarioDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="usuario">¿Desea eliminar el usuario <b>{{usuario.clave}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUsuarioDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteUsuario" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteUsuariosDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="usuario">¿Desea eliminar los usuarios seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUsuariosDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedUsuarios" />
					</template>
				</Dialog>

				<Dialog v-model:visible="asignarusuarioDialog" :style="{width: '80%'}" header="Asignar" :modal="true">
					<div class="p-grid" style="margin-top: 5px">
						<div class="p-col-6">
							<Button label="Asignar Pantalla.."  class="p-button-success p-mr-2" @click="AsignarPermisos" :disabled="!selectedPantallaActivo || !selectedPantallaActivo.length" />
						</div>
						<div class="p-col-6">
							<Button label="Desasignar Pantalla.."  class="p-button-success p-mr-2" @click="NoAsignarPermisos" :disabled="!selectedPantallaInActivo || !selectedPantallaInActivo.length" />

						</div>
					</div>
					<div class="p-grid">					 
						<div class="p-col-6">
							 <DataTable  ref="dt" :value="usuariopantallaactivo" v-model:selection="selectedPantallaActivo" :key="PantallaActivaKey" dataKey="nombre">
								<template #header>
									<div class="table-header">
										<h5 class="p-m-0">Pantallas No Asignadas</h5>					 
									</div>
								</template>

								<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>			 
								<Column field="nombre" header="Nombre" :sortable="true"></Column>                  
							</DataTable>

						</div>
						<div class="p-col-6">
							 
							 <DataTable ref="dt" :value="usuariopantallainactivo" v-model:selection="selectedPantallaInActivo" :key="PantallaInActivaKey" dataKey="nombre" 
									  >
								<template #header>
									<div class="table-header">
										<h5 class="p-m-0">Pantallas Asignadas</h5>
									 
									</div>
								</template>

								<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>			 
								<Column field="nombre" header="Nombre" :sortable="true"></Column>                  
							</DataTable>

						</div>
					</div>
					<div>
						<!-- <Button label="Desasignar Pantalla.."  class="p-button-success p-mr-2" @click="NoAsignarPermisos" :disabled="!selectedPantallaInActivo || !selectedPantallaInActivo.length" /> -->

					</div>
					<div  >
							 <!-- <DataTable ref="dt" :value="usuariopantallainactivo" v-model:selection="selectedPantallaInActivo" :key="PantallaInActivaKey" dataKey="nombre" 
									  >
								<template #header>
									<div class="table-header">
										<h5 class="p-m-0">Pantallas Asignadas</h5>
									 
									</div>
								</template>

								<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>			 
								<Column field="nombre" header="Nombre" :sortable="true"></Column>                  
							</DataTable> -->
					
					 </div>
						 

					<!-- </div>   -->
	 
				
				 
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import UsuarioService from '../service/UsuarioService';
import Cookies from "js-cookie"
window.Cookies = Cookies
import moment from 'moment';   
 import XLSX from 'xlsx'
export default {
 
	data() {
		return {
			usuarios: null,
			usuarioDialog: false,
			deleteUsuarioDialog: false,
			deleteUsuariosDialog: false,
			usuario: {},
			selectedUsuarios: null,
			filters: {},
			submitted: false,
			asignarusuarioDialog:false,
			estadoKey: 0,
			usuariopantallaactivo:null,
			usuariopantallainactivo:null,
			usuariopantallaactivo2:[],
			usuariopantallaactivo3:null,
			PantallaActivaKey:0,
			PantallaInActivaKey:0,
			selectedPantallaActivo:null,
			selectedPantallaInActivo:null,
			userdata:null,
			asignarpantalla:[],
			datadelete:null,
			json_data: [
            {
                'name': 'Tony Peña',
                'city': 'New York',
                'country': 'United States',
                'birthdate': '1978-03-15',
                'phone': {
                    'mobile': '1-541-754-3010',
                    'landline': '(541) 754-3010'
                }
            },
            {
                'name': 'Thessaloniki',
                'city': 'Athens',
                'country': 'Greece',
                'birthdate': '1987-11-23',
                'phone': {
                    'mobile': '+1 855 275 5071',
                    'landline': '(2741) 2621-244'
                }
            }
        ],
			UsuarioExpor:null,
 
		}
	},
 
	usuarioService: null,
	created() {
        this.usuarioService = new UsuarioService();
        console.log(this.usuarioService);
	},
	mounted() {
        this.usuarioService.getUsuarios().then(data => {
			this.usuarios = data			
		});
	},
	methods: {
		forceRerender() {
			  this.estadoKey += 1;
			  this.PantallaActivaKey+=1;
			  this.PantallaInActivaKey+=1;
    	},
		openNew() {
			this.usuario = {};
			this.submitted = false;
			this.usuarioDialog = true;
			this.usuario.activo=true;
		},
		hideDialog() {
			this.usuarioDialog = false;
			this.submitted = false;
		},
		saveUsuario() {
			this.submitted = true;
           
			if (this.usuario.clave.trim()) {
				if (this.usuario.id_usuario>0) 
				{
					
					this.usuarioService.updateUsuarios(this.usuario).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Usuario actualizado', life: 3000});
					 
					this.usuarioService.getUsuarios().then(data =>{ 
						this.usuarios = data
						document.location.reload();	
					});
					
				}
				else { 
					 
					this.usuarioService.createUsuarios(this.usuario).then(data => {this.usuario = data});
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Usuario creado', life: 3000});
					 
					this.usuarioService.getUsuarios().then(data => {
						this.usuarios = data;
						document.location.reload();
					} );
					
				}

				this.usuarioDialog = false;
				this.usuario = {};
			}
		},
		editUsuario(usuario) {
			this.usuario = {...usuario};
			this.usuarioDialog = true;
		},
		confirmDeleteUsuario(usuario) {
			this.usuario = usuario;
			this.deleteUsuarioDialog = true;
		},
		deleteUsuario() {
			
			var d = new Date();
			// this.usuarios = this.usuarios.filter(val => val.id !== this.usuario.id);
			this.deleteUsuarioDialog = false;
			// this.usuario = {};
			this.usuario.activo=0;
			this.usuario.fecha_baja=this.computedDatesFormatSave(d);
			this.usuarioService.updateUsuarios(this.usuario).then(document.location.reload());					 
				
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Usuario eliminado', life: 3000}); 
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.usuarios.length; i++) {
				if (this.usuarios[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.usuarios.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteUsuariosDialog = true;
		},
		deleteSelectedUsuarios() {
			this.usuarios = this.usuarios.filter(val => !this.selectedUsuarios.includes(val));
			this.deleteUsuariosDialog = false;
			this.selectedUsuarios = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Usuarios eliminados', life: 3000});
		},
		PermisosN(){
			this.asignarusuarioDialog=true; 
			// console.log(this.selectedUsuarios[0]);
			this.usuarioService.getUsuariosPantallaInActivo(this.selectedUsuarios[0]).then(data => {
				this.usuariopantallainactivo = data;
				//  console.log(this.usuariopantallainactivo);	
			});
			this.usuarioService.getUsuariosPantallaActivo(this.selectedUsuarios[0]).then(data => {
				this.usuariopantallaactivo = data;
			});

			
			// getUsuariosPantallaActivo
		},
		AsignarPermisos(){
			
			for(var i in this.selectedPantallaActivo){
				this.asignarpantalla.push({
				'id_pantalla':this.selectedPantallaActivo[i].id_pantalla,
				'id_usuario':this.selectedUsuarios[0].id_usuario
				});
				this.usuarioService.createUsuariosPantallaActivo(this.asignarpantalla[i]).then(data =>{
					this.datadelete = data;
				
					this.usuarioService.getUsuariosPantallaInActivo(this.selectedUsuarios[0]).then(data => {
					this.usuariopantallainactivo = data;
					//  console.log(this.usuariopantallainactivo);	
					});
					this.usuarioService.getUsuariosPantallaActivo(this.selectedUsuarios[0]).then(data => {
						this.usuariopantallaactivo = data;
				});
				});
				
				


			} 
			
			
			let useconver = Cookies.get("user"); 
			let userdata=JSON.parse(useconver) ;
			this.usuarioService.getUsuariosPantallaInActivo(userdata[0]).then(data => {
				  Cookies.set("userpers","null") ;
				 document.cookie =('userpers='+JSON.stringify(data));
				//  console.log(this.usuariopantallainactivo);	
			});
			this.asignarpantalla=[];
			this.selectedPantallaActivo=[];

			 
			

			
		},
		NoAsignarPermisos(){
			
			for(var i in this.selectedPantallaInActivo){
				 
                this.usuarioService.deleteUsuariosPantallaInActivo(this.selectedPantallaInActivo[i]).then(data => {
				this.datadelete = data;
				
				this.usuarioService.getUsuariosPantallaInActivo(this.selectedUsuarios[0]).then(data => {
				this.usuariopantallainactivo = data;
				//  console.log(this.usuariopantallainactivo);	
				});
				this.usuarioService.getUsuariosPantallaActivo(this.selectedUsuarios[0]).then(data => {
					this.usuariopantallaactivo = data;
				});
				});	
			} 
			this.selectedPantallaInActivo=null;
			 
			this.selectedPantallaActivo=null;
			let useconver = Cookies.get("user"); 
			let userdata=JSON.parse(useconver) ;
			this.usuarioService.getUsuariosPantallaInActivo(userdata[0]).then(data => {
				//  Cookies.set("userpers","null") ;
				//  document.cookie =('userpers='+JSON.stringify(data));
				 localStorage.setItem('permisos', JSON.stringify(data))
				//  console.log(this.usuariopantallainactivo);	
			});

		
		},
		turno(value){
			if(value ==true){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		computedDatesFormat(value) {
                return moment(value).format('DD/MM/YYYY')
		},
		computedDatesFormatSave(value) {
                return moment(value).format('YYYY/MM/DD')
		},
		download : function() {
			this.usuarioService.getUsuariosExport().then(data => {

			

			this.UsuarioExpor = data;
			for(var i in  this.UsuarioExpor)
			{ 
				this.UsuarioExpor[i].contrasena="*************";
			}
				if(this.UsuarioExpor != null){
				const data = XLSX.utils.json_to_sheet(this.UsuarioExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoUsuario.xlsx')
			}
		});
 
      },
	 
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.usuario-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .usuario-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.usuario-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
